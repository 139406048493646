.pagination {
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
    .page {
        &-item {
            &:first-child {
                .page-link {
                    border-radius: 8px 0 0 8px;
                }
            }
            &:last-child {
                .page-link {
                    border-radius: 0 8px 8px 0;
                }
            }
            &:not(:last-child) {
                margin-right: 2px;
            }
            &.disabled {
                .page-link {
                    border-color: $main-dark-gray;
                    background-color: $main-light-gray;
                }
            }
            &.active {
                .page-link {
                    border-color: $main-dark-gray;
                    background-color: $main-dark-gray;
                    cursor: default;
                    &:hover {
                        color: $white;
                    }
                }
            }
        }
        &-link {
            border-color: $main-dark-gray;
            font-family: $base-font-sans-serif;
            font-size: 16px;
            color: $black;
            user-select: none;
            transition: 0.25s color ease-in-out, 0.25s background-color ease-in-out;
            &:hover {
                color: $white;
                background-color: $main-dark-gray;
            }
            &:focus {
                box-shadow: unset;
            }
        }
    }
}
