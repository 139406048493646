// Colors
$gray: #C4C6CF;

$black: #000;
$white: #fff;

$blue: #2F61D5;
$blue-second: #144359;
$green: #81969E;
$green-second: #87ABAE;
$dark-green: #60877C;
$dark-green_v2: #3F6057;
$yellow: #FFA607;
$green-third: #5E7A80;
$green-light: #c5d8df;
$yellow-light: #FFF3DC;
$yellow-middle: #FFDAA7;

$main-dark-green: #7c9498;
$main-light-green: #a6c3c6;
$main-gray: #c3cdd0;
$main-dark-gray: #4b5457;
$main-light-gray: #e6e6e3;
$main-light-gray-2: #8f9fa3;

$base-font-sans-serif: 'Roboto', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$base-font-serif: 'Times New Roman', 'Times', 'Baskerville', 'Georgia', serif;

// Fonts
$lora-font-family: 'Lora';
$lora-font-path: "/fonts/lora";
$lora: $lora-font-family, 'Times New Roman', 'Times', 'Baskerville', 'Georgia', serif;

$avenir-next-font-family: 'AvenirNextCyr';
$avenir-next-font-path: "/fonts/avenir-next";
$avenir-next: $avenir-next-font-family, 'Roboto', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;

// Body
$base-text-color: $black;
$base-background-color: $green;
$font-size-base: 20px;
$line-height-base: 1.2 !default;
$base-font-family: $avenir-next !default;

$base-min-width: 320px;

// Liks
$base-link-color: $dark-green_v2;

// Headers
$h1-font-size: 30px !default;
$h2-font-size: 40px !default;
$h3-font-size: 24px !default;
$h4-font-size: 21px !default;
$h5-font-size: 17px !default;
$h6-font-size: 15px !default;

$headings-font-family: inherit !default;
$headings-color: inherit !default;

$animation-speed: 0.3s;
