.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    #header {
        background-color: $white;
    }
    #content {
        @media all and (min-width: 1024px) {
            margin: 0 20px;
        }
        @media (min-width: 1024px) and (max-width: 1180px) and (min-height: 768px) and (max-height: 820px) and (orientation: landscape) {
            margin: 0;
        }
    }
}

.container {
    max-width: 1370px;
    margin: 0 auto;
    padding: 24px;
    &--no-bottom-padding {
        padding-bottom: 0;
    }
}

.button {
    position: relative;
    display: inline-block;
    border: 1px solid $main-dark-gray;
    border-radius: 8px;
    padding: 6px 15px;
    font-family: $lora;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    color: $black;
    background-color: $white;
    transition: all .3s;
    &:hover {
        color: $white;
        background-color: $main-dark-gray;
    }
    &--min-width-165 {
        min-width: 165px;
    }
    &--bold-font {
        font-weight: 600;
    }

    .homepage__actions & {
        min-width: 240px;
        margin-right: 15px;
        margin-left: 15px;
    }
}

.form-control {
    height: 32px;
    border: 1px solid $main-dark-gray;
    border-radius: 8px !important;
    font-family: $avenir-next;
    font-size: 14px;
    line-height: 1.2;
    color: $black;
    background-color: $white;
    transition: all .3s;
    @include placeholder {
        color: $main-light-gray-2;
    }
    &:focus {
        box-shadow: 0 0 2px 2px rgba($main-light-green, 0.5);
        @include placeholder {
        }
    }
    &--search {
        height: unset;
        border: unset;
        border-radius: 10px 0 0 10px !important;
        padding: 15px 20px;
        font-family: $lora;
        font-size: 16px;
        color: $black;
        background-color: $white;
        outline: none;
        box-shadow: unset;
        &::placeholder {
            color: $black;
            transition: 0.25s opacity ease-in-out;
        }
        &:focus {
            box-shadow: inset 0 0 2px 1px rgba($main-light-green, 0.25);
            &::placeholder {
                opacity: 0;
            }
        }
    }
}

.category-list {
    display: flex;
    align-items: center;
    justify-content: center;
    @media all and (max-width: 480px) {
        gap: 10px;
    }
    @media (min-width: 481px) and (max-width: 767px) {
        gap: 15px;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
        gap: 25px;
    }
    @media all and (min-width: 1024px) {
        gap: 35px;
    }
}

.category-tile {
    display: inline-block;
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
    background-color: $white;
    transition: 0.35s box-shadow ease-in-out;
    @media all and (max-width: 767px){
        width: 100px;
        max-width: 100px;
        height: 140px;
        padding: 5px;
    }
    @media (min-width: 768px) and (max-width: 1023px){
        width: 120px;
        max-width: 120px;
        height: 155px;
    }
    @media all and (min-width: 768px){
        padding: 10px;
    }
    @media all and (min-width: 1024px){
        width: 150px;
        max-width: 150px;
        height: 195px;
    }
    &__icon {
        display: block;
        @media all and (max-width: 767px){
            width: 50px;
            height: 50px;
        }
        @media all and (min-width: 768px){
            width: 60px;
            height: 60px;
        }
        &-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            background-color: $main-light-green;
            @media all and (max-width: 767px){
                max-width: 90px;
                height: 90px;
            }
            @media (min-width: 768px) and (max-width: 1023px){
                max-width: 100px;
                height: 100px;
            }
            @media all and (min-width: 1024px){
                max-width: 130px;
                height: 130px;
            }
            &--bg-main-gray {
                background-color: $main-gray;
            }
            &--bg-main-dark-gray {
                background-color: $main-dark-gray;
            }
        }
    }
    &__title {
        font-family: $lora;
        font-size: 16px;
        text-transform: uppercase;
        color: $black;
        &-wrap {
            display: block;
            text-align: center;
            @media all and (max-width: 1023px){
                padding: 10px 0 0;
            }
            @media all and (min-width: 1024px){
                padding: 15px 0 0;
            }
        }
    }
    &:hover {
        box-shadow: 0 1px 8px 2px rgba(0,0,0,0.2);
    }
}

.homepage {
    @media all and (min-width: 1024px) {
        padding: 40px 24px;
    }
    &__title {
        font-family: $base-font-serif;
        font-style: italic;
        text-align: center;
        font-size: 42px;
        line-height: 1.2;

        @include media('>=tablet') {
            font-size: 72px;
        }
    }
    &__section {
        margin: 0 auto;
        border-radius: 15px;
        padding: 20px;
        @media (min-width: 768px) and (max-width: 1023px) {
            max-width: 500px;
        }
        @media all and (min-width: 1024px) {
            max-width: 900px;
        }
        &:not(:last-child) {
            margin: 0 auto 40px;
        }
        &-inner {
            position: relative;
            max-width: 660px;
            margin: 0 auto;
        }
        &-item {
            @media all and (max-width: 519px) {
                width: 50%;
                &:nth-child(2n) {
                    text-align: right;
                }
            }
        }
        &-image {
            position: relative;
            z-index: 1;
            width: 100%;
            max-width: 100%;
            max-height: 100%;
            &-wrap {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                border-radius: 15px;
                overflow: hidden;
                @media all and (min-width: 1024px) {
                    align-items: center;
                }
            }
        }
        &-content {
            position: relative;
            max-width: 600px;
            text-align: center;
            &-wrap {
                position: absolute;
                z-index: 2;
                right: 10px;
                bottom: 10px;
                left: 10px;
                min-height: 300px;
                border-radius: 10px;
                padding: 45px 10px 10px;
                background-color: rgba($white, 50%);
                @media (min-width: 480px) and (max-width: 767px) {
                    right: 15px;
                    left: 15px;
                    bottom: 5%;
                    padding: 45px 15px 20px;
                }
                @media (min-width: 768px) and (max-width: 1023px) {
                    right: 15px;
                    left: 15px;
                    bottom: 15px;
                    padding: 50px 15px 15px;
                }
                @media all and (min-width: 1024px) {
                    right: 30px;
                    left: 30px;
                    bottom: unset;
                    margin-top: 60px;
                    padding: 80px 30px 60px;
                }
            }
            &-text {
                position: relative;
                margin-bottom: 25px;
                font-family: $avenir-next;
                font-style: italic;
                font-size: 16px;
                line-height: 1.2;
                text-align: center;
                color: $black;
                @media (min-width: 480px) and (max-width: 767px) {
                    font-size: 17px;
                }
                @media all and (min-width: 768px) {
                    font-size: 19px;
                    line-height: 1.4;
                }
                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    width: 30px;
                    height: 20px;
                    background-image: url('/images/icons/quotes-icon.svg');
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    @media all and (min-width: 768px) {
                        width: 37px;
                        height: 24px;
                    }
                }
                &:before {
                    top: -35px;
                    left: -5px;
                    transform: rotate(180deg);
                    @media (min-width: 768px) and (max-width: 1023px) {
                        top: -40px;
                        left: 0;
                    }
                    @media all and (min-width: 1024px) {
                        top: -45px;
                    }
                }
                &:after {
                    right: -5px;
                    bottom: -35px;
                    @media (min-width: 768px) and (max-width: 1023px) {
                        right: 0;
                        bottom: -40px;
                    }
                    @media all and (min-width: 1024px) {
                        bottom: -45px;
                    }
                }
                p {
                    margin: 0 0 5px;
                    @media all and (min-width: 480px) and (max-width: 1023px) {
                        margin: 0 0 10px;
                    }
                    @media all and (min-width: 1024px) {
                        margin: 0 0 20px;
                    }
                }
            }
            &-signature {
                max-width: 300px;
                width: 100%;
                height: auto;
                margin: 0 auto;
                overflow: hidden;
                &-wrap {}
                &-image {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            &-name {
                position: relative;
                display: inline-block;
                margin-left: auto;
                font-family: $lora;
                font-size: 12px;
                line-height: 1.5;
                text-transform: uppercase;
                text-align: center;
                color: $black;
                @media all and (min-width: 768px) {
                    text-align: right;
                }
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-color: $black;
                }
                &-wrap {
                    text-align: center;
                    @media all and (min-width: 768px) {
                        padding-right: 40px;
                        text-align: right;
                    }
                }
            }
        }
        &--padding-md-desktop {
            padding: 20px;
            @media all and (min-width: 1024px) {
                padding: 40px;
            }
        }
        &--padding-sm-mobile {
            @media all and (max-width: 419px) {
                padding: 10px;
            }

        }
        &--no-padding {
            padding: 0;
        }
    }
    &__btn-wrap {
        text-align: center;
        &:not(:last-child) {
            margin-bottom: 25px;
        }
    }

    &__sections {
        margin: 32px auto;

        @include media('>=tablet') {
            display: flex;
            justify-content: center;
            gap: 32px;
            margin: 32px auto 64px;
        }

        &-item {
            margin-bottom: 24px;
            cursor: pointer;
            max-width: 400px;
            @media all and (max-width: 767px) {
                margin: 0 auto 24px;
            }

            @include media('>=tablet') {
                margin-bottom: 0;
            }
        }

        &-link {
            position: relative;
            display: block;
            border: 5px solid $white;
            padding: 8px;

            &:after {
                @include animate(background-color);
                content: '';
                position: absolute;
                top: 8px;
                right: 8px;
                bottom: 8px;
                left: 8px;
                background-color: $white;
                opacity: 1;
            }

            &:hover {
                .homepage__sections-title {
                    color: $yellow;
                }
            }
            &:focus {
                outline: 2px auto $white;
            }
        }

        &-title {
            position: absolute;
            z-index: 2;
            top: 50%;
            left: 50%;
            font-family: $base-font-serif;
            font-weight: 400;
            line-height: 1.5;
            text-align: center;
            text-transform: uppercase;
            color: $green;
            transform: translate(-50%, -50%);
            @include animate(color);

            @include media('>=tablet') {
                font-size: 28px;
            }
            @media (min-width: 1024px) and (max-width: 1199px) {
                font-size: 30px;
            }
            @media all and (min-width: 1200px) {
                font-size: 36px;
            }
        }
    }

    &__actions {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    &__welcome {
        margin: 0 auto 40px;
        border-radius: 15px;
        padding: 20px 20px 40px;
        background-color: $white;
        @media all and (min-width: 1024px) {
            max-width: 900px;
        }
        @media (min-width: 768px) and (max-width: 1023px) {
            max-width: 500px;
        }
        &-inner {
            max-width: 500px;
            margin: 0 auto;
        }
        &-content {
            text-align: center;
            p {
                margin-bottom: 25px;
                font-family: $avenir-next;
                font-size: 19px;
                line-height: 1.2;
                color: $black;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            &-text {
                font-family: $lora;
                font-size: 21px;
                line-height: 1.2;
            }
        }
    }
}

.content {
    $block-name: &;
    &__title {
        position: relative;
        padding: 0 0 15px;
        font-family: $avenir-next;
        font-size: 30px;
        font-weight: normal;
        line-height: 1.2;
        text-align: center;
        color: $main-dark-gray;
        margin-bottom: 20px;

        &:after {
            content: '';
            position: absolute;
            top: 100%;
            left: calc(50% - 118px);
            width: 236px;
            height: 1px;
            background-color: $main-dark-gray;
        }
        &--offset-top {
            padding-top: 30px;
        }
        &--main-dark-green {
            color: $main-dark-green;
        }
    }

    &__grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @media all and (max-width: 768px) {
            margin: 0 -15px;
        }

        &-item {
            width: 50%;
            padding: 0 15px;
            @media all and (max-width: 767px) {
                &:nth-child(n + 3) {
                    margin: 30px 0 0;
                }
            }
            @media (min-width: 768px) and (max-width: 1023px) {
                width: 33.3333%;
                padding: 0 20px;
                &:nth-child(n + 4) {
                    margin: 40px 0 0;
                }
            }
            @media all and (min-width: 1024px) {
                width: 25%;
                padding: 0 30px;
                &:nth-child(n + 5) {
                    margin: 60px 0 0;
                }
            }
        }

        &-link {
            position: relative;
            display: block;
            border-radius: 10px;
            padding: 5px;
            text-decoration: none;
            background-color: $white;
            transition: 0.25s box-shadow ease-in-out;
            @media all and (min-width: 480px) {
                border-radius: 15px;
            }
            @media (min-width: 480px) and (max-width: 767px) {
                padding: 10px;
            }
            @media all and (min-width: 768px) {
                border-radius: 10px;
                padding: 15px;
            }
            &:hover {
                box-shadow: 0 0 7px 2px rgba(0,0,0,.35);
                .content {
                    &__grid {
                        &-title,
                        &-date {
                            color: $main-dark-green;
                        }
                    }
                }
            }
            &:focus {
                outline: 2px auto $white;
            }

            p {
                font-family: $base-font-serif;
            }
            &-content {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 75px;
                &-top {
                    min-height: 51px;
                    margin-bottom: 5px;
                }
                &-top,
                &-bottom {
                    display: flex;
                    justify-content: space-between;
                }
                &-bottom {
                    align-items: center;
                }
            }
            &-icon {
                position: relative;
                z-index: 10;
                display: block;
                width: 20px;
                height: 20px;
                &:not(:last-child) {
                    margin: 0 10px 0 0;
                }
                &.add-to-bookmark {
                    cursor: pointer;
                    &:hover {
                        path {
                            fill: $main-light-green;
                        }
                    }
                }
                &-wrap {
                    display: flex;
                    margin-left: 5px;
                }
                &-svg {
                    display: block;
                    max-width: 100%;
                    max-height: 100%;
                    path,
                    rect,
                    polygon {
                        transition: 0.25s fill ease-in-out;
                    }
                }
            }
            &--centered {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }

        &-title {
            flex-grow: 1;
            max-height: 51px;
            overflow: hidden;
            font-family: $lora;
            font-size: 14px;
            line-height: 1.2;
            font-weight: 500;
            color: $black;
            transition: 0.25s color ease-in-out;
        }
        &-date {
            display: block;
            overflow: hidden;
            font-family: $avenir-next;
            font-size: 10px;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: $black;
            cursor: default;
            transition: 0.25s color ease-in-out;
        }

        &-text {
            font-family: $base-font-serif;
            text-align: center;
        }

        &-image {
            display: block;
            margin: 0 auto 24px;
            &-wrap {
                position: relative;
                display: block;
                margin-bottom: 15px;
                border-radius: 10px;
                overflow: hidden;
                padding-bottom: 100%;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                @media all and (max-width: 767px) {
                    margin-bottom: 5px;
                }
            }
        }
        &-favorite {
            position: absolute;
            z-index: 2;
            top: -10px;
            right: 10px;
            @media all and (min-width: 768px) and (max-width: 1199px) {
                right: 30px;
            }
            @media all and (min-width: 1200px) {
                right: 40px;
            }
            &-icon {
                display: block;
                width: 25px;
                height: 25px;
                @media all and (min-width: 768px) {
                    width: 35px;
                    height: 35px;
                }
                &-svg {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }

    }
    &__search {
        width: 100%;
        margin: 0 auto 30px;

        @media all and (min-width: 768px) {
            width: calc(100% / 2);
            padding: 0 10px;
        }
        @media all and (min-width: 1024px) {
            width: calc(100% / 3);
        }
    }
    &__pagination {
        display: flex;
        justify-content: center;
    }

    &.categories,
    &.homepage,
    &.articles {
        #{$block-name} {
            &__title {
                text-transform: uppercase;
            }
        }
    }
    &.articles {
        &__content {
            &-link {
                p {
                    margin: 0 auto;
                }
            }
        }
        #{$block-name} {
            &__grid {
                &-link {
                    height: 100%;
                    p {
                        margin: 10px auto 0;
                        &:empty {
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }
    &.categories {
        .content {
            &__grid {
                &-item:not(:first-child) {
                    .content {
                        &__grid {
                            &-title {
                                margin-bottom: 0;
                            }
                            &-image {
                                margin: 0;
                            }
                            &-text {
                                &:empty {
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.article {
        &__content {

        &.video,
        &.podcast {
            margin: 0 auto;
        }
        &.podcast {
            max-width: 500px;
            .article__content-image-wrap {
                margin: 0 auto;
            }
        }
        &.video {
            max-width: 1200px;
        }

        &.article{
            position: relative;
            padding: 20px 0 10px;
            @include media('>=desktop') {
                display: flex;
                gap: 20px;
                margin-bottom: 0;
            }
        }

        &-image {
            margin-bottom: 20px;
            a {
                display: block;
                width: 100%;
                @media all and (min-width: 480px) {
                    display: block;
                    width: 300px;
                }
            }

            img {
                width: 100%;
            }
        }

        &-video {
            margin-bottom: 32px;

            iframe {
                width: 100%;
            }
            &.no-video {
                text-align: center;
            }
        }

        &-audio {
            margin-bottom: 24px;

            audio {
                width: 100%;
            }
        }

        &-text {
            .video &,
            .podcast & {
                audio {
                    margin-bottom: 20px;
                }
            }
            p {
                margin: 0;
                font-family: $avenir-next;
                font-size: 18px;
                color: $main-dark-gray;
                &:not(:last-child) {
                    margin: 0 0 25px 0;
                }
            }
            .no-content {
                text-align: center;
            }
        }
        audio {
            width: 100%;
        }
        &-link {
            &:last-child {
                margin: 0 auto;
            }
        }
    }
    &__inner {
        margin: 0 auto;
        border-radius: 15px;
        padding: 25px;
        background-color: $white;
        @media all and (min-width: 1024px) {
            max-width: 1240px;
        }
    }
}

.login,
.register,
.password,
.login-register {
    &__inner {
        max-width: 440px;
        margin: 0 auto;
        border-radius: 15px;
        padding: 25px;
        background-color: $white;
        @media (min-width: 768px) and (max-width: 1023px) {
            max-width: 490px;
        }
        @media all and (min-width: 1024px) {
            max-width: 900px;
        }
        &:not(:first-child) {
            margin-top: 40px;
        }
    }
    &__card {
        max-width: 440px;
        margin: 0 auto;
    }
    .card {
        border: none;
        background-color: transparent;

        &-header {
            position: relative;
            margin-bottom: 20px;
            border: none;
            font-family: $base-font-serif;
            line-height: 1.2;
            text-align: center;
            text-transform: uppercase;
            color: $main-dark-gray;
            background-color: transparent;
            @media all and (max-width: 360px) {
                padding: 0 5px 15px;
                font-size: 26px;
            }
            @media all and (min-width: 361px) {
                padding: 0 20px 15px;
                font-size: 30px;
            }

            &:after {
                content: '';
                position: absolute;
                top: 100%;
                left: calc(50% - 118px);
                width: 236px;
                height: 1px;
                background-color: $black;
            }
        }
        &-body {
            padding: 20px 0 10px;
        }

        .form-check {
            padding-left: 0;

            &-input {
                position: absolute;
                opacity: 0;

                &+label {
                    position: relative;
                    cursor: pointer;
                    padding: 0;
                    font-family: $avenir-next;
                }

                // Box.
                &+label:before {
                    content: '';
                    flex-shrink: 0;
                    margin-right: 5px;
                    display: inline-block;
                    vertical-align: text-top;
                    width: 15px;
                    height: 15px;
                    border: 1px solid $main-dark-gray;
                    border-radius: 6px;
                    background-color: $white;
                    transition: 0.25s background-color ease-in-out;
                }

                // Box hover
                &:hover+label:before {
                    background-color: $main-light-gray;
                }

                // Box focus
                &:focus+label:before {
                    box-shadow: 0 0 2px 2px rgba($main-light-green, 0.5);
                }

                // Box checked
                //&:checked+label:before {
                    //background: $dark-green;
                    //background-color: $main-dark-gray;
                //}

                // Disabled state label.
                &:disabled+label {
                    color: $main-light-gray-2;
                    cursor: not-allowed;
                }

                // Disabled box.
                &:disabled+label:before {
                    box-shadow: none;
                    border-color: $main-light-gray-2;
                    background-color: $main-light-gray;
                }

                // Checkmark. Could be replaced with an image
                &:checked+label:after {
                    content: '';
                    position: absolute;
                    left: 2px;
                    top: 7px;
                    background: $main-dark-gray;
                    width: 2px;
                    height: 2px;
                    box-shadow:
                        2px 0 0 $main-dark-gray,
                        4px 0 0 $main-dark-gray,
                        4px -2px 0 $main-dark-gray,
                        4px -4px 0 $main-dark-gray,
                        4px -6px 0 $main-dark-gray,
                        4px -8px 0 $main-dark-gray;
                    transform: rotate(45deg);
                }
            }

            &-label {
                display: flex;
                font-size: 14px;
                color: $main-dark-gray;
            }
        }

        .register-link {
            font-family: $avenir-next;
            font-weight: 700;
            color: $main-dark-gray;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
            &--underline {
                text-decoration: underline;
            }
        }

    }

    .action__buttons {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &--bg-light-gray {
        background-color: $main-light-gray;
    }
    &--border-radius {
        border-radius: 40px;
    }
}

.login-register {
    .login-section {
        @media all and (max-width: 991px) {
            margin-bottom: 50px;
        }
    }
}


.navbar-nav {
    &--align-center {
        @media all and (max-width: 767px) {
            text-align: center;
        }
        @media all and (min-width: 768px) {
            width: 100%;
            justify-content: center;
        }
        .nav-link {
            @media all and (max-width: 767px) {
                width: 100%;
                max-width: 400px;
            }
        }
    }
    .nav-link {
        font-family: $base-font-serif;
        &:hover {
            color: $yellow !important;
        }
    }
}

.navbar {
    &-collapse {
        @media all and (min-width: 768px) {
            flex-wrap: wrap;
        }
    }
    &__user {
        margin-left: auto;
        margin-bottom: 20px;
        text-align: right;
        color: $green;
        &-icon {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            overflow: hidden;
        }
    }
}

.bg-green-third {
    background-color: $green-third;
}

.bg-blue-second {
    background-color: $blue-second;
}

.category-block {
    $block-name: &;
    position: relative;
    margin: 0 -24px;
    border-radius: 15px;
    padding: 10px 15px 15px;
    background-color: $main-light-gray;
    &:before {
        content: "";
        position: absolute;
        bottom: -1px;
        left: calc(50% - 75px);
        width: 150px;
        height: 2px;
        background-color: $black;
        @media all and (min-width: 768px) {
            left: calc(50% - 163px);
            width: 326px;
        }
    }
    @media all and (min-width: 768px) {
        border-radius: 40px;
    }
    &__list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        max-width: 760px;
        overflow-y: hidden;
        margin: 0 auto;
        padding: 0;
        list-style: none;
        //@media all and (min-width: 768px) {
        //    max-height: 60px;
        //}
        //&--open {
        //    max-height: unset;
        //}
        &-item {
            padding: 0 10px;
            @media all and (max-width: 767px) {
                width: calc(100% / 2);
                &:nth-child(n + 3) {
                    margin-top: 15px;
                }
            }
            @media all and (min-width: 768px) {
                width: calc(100% / 4);
                &:nth-child(n + 5) {
                    margin-top: 15px;
                }
            }
            &.active {
                pointer-events: none;
                #{$block-name} {
                    &__link {
                        color: $white;
                        background-color: $main-light-gray-2;
                    }
                }
            }
        }
    }
    &__link {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 60px;
        border-radius: 10px;
        overflow: hidden;
        padding: 5px 10px;
        font-family: $avenir-next;
        font-size: 10px;
        line-height: 1.4;
        text-transform: uppercase;
        text-align: center;
        color: $black;
        background-color: $white;
        user-select: none;
        transition: 0.35s background-color ease-in-out, 0.35s color ease-in-out;
        @media all and (min-width: 768px) {
            font-size: 12px;
        }
        &:hover {
            color: $white;
            background-color: $main-light-gray-2;
        }

    }
    //&__toggle {
    //    padding-top: 10px;
    //    text-align: center;
    //    &-btn {
    //        display: inline-flex;
    //        flex-direction: column;
    //        justify-content: center;
    //        align-items: center;
    //        cursor: pointer;
    //        &-title {
    //            font-family: $lora;
    //            font-size: 10px;
    //            line-height: 1.2;
    //            text-transform: uppercase;
    //        }
    //        &-icon {
    //            position: relative;
    //            width: 11px;
    //            height: 11px;
    //            &:before,
    //            &:after {
    //                content: "";
    //                position: absolute;
    //                top: 5px;
    //                width: 7px;
    //                height: 2px;
    //                background-color: $black;
    //                transition: 0.35s transform ease-in-out;
    //            }
    //            &:before {
    //                left: 0;
    //                transform: rotate(45deg);
    //            }
    //            &:after {
    //                right: 0;
    //                transform: rotate(-45deg);
    //            }
    //        }
    //        &--open {
    //            #{$block-name} {
    //                &__toggle-btn {
    //                    &-icon {
    //                        &:before {
    //                            transform: rotate(-45deg);
    //                        }
    //                        &:after {
    //                            right: 0;
    //                            transform: rotate(45deg);
    //                        }
    //                    }
    //                }
    //            }
    //        }
    //    }
    //}
}
.form-group {
    &--search {
        display: flex;
        margin: 0;
        .btn {
            margin: 0;
            &--search {
                position: relative;
                z-index: 1;
                width: 50px;
                border-radius: 0 10px 10px 0;
                background-color: $white;
                transition: 0.25s background-color ease-in-out;
                &:hover {
                    background-color: lighten($green-light, 5%);
                }
            }
        }
    }
    &--offset-bottom {
        margin-bottom: 35px;
    }
}

.terms-page {
    &__inner {
        max-width: 440px;
        margin: 0 auto;
        border-radius: 15px;
        padding: 25px;
        background-color: $white;
        @media (min-width: 768px) and (max-width: 1023px) {
            max-width: 490px;
        }
        @media all and (min-width: 1024px) {
            max-width: 900px;
        }
    }
    &__card {
        margin: 0 auto;
    }
    .card {
        border: none;
        background-color: transparent;
        &-header {
            position: relative;
            margin-bottom: 20px;
            border: none;
            padding: 0 20px 15px;
            font-family: $avenir-next;
            font-size: 30px;
            line-height: 1.2;
            text-align: center;
            text-transform: uppercase;
            color: $main-dark-gray;
            background-color: transparent;

            &:after {
                content: '';
                position: absolute;
                top: 100%;
                left: calc(50% - 118px);
                width: 236px;
                height: 1px;
                background-color: $black;
            }
        }
        &-body {
            padding: 20px 0 10px;
            p {
                margin: 0 0 25px 0;
                font-family: $avenir-next;
                font-size: 18px;
                line-height: 1.2;
                color: $main-dark-gray;
                &:last-child {
                    margin: 0;
                }
            }
        }
    }
    &--offset-y {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

#footer {
    .navbar-nav {
        @media all and (max-width: 768px - 1px) {
            padding: 10px 0;
        }
        .nav-item {
            &:not(:last-child) {
                @media all and (max-width: 768px - 1px) {
                    margin: 0 0 10px;
                }
            }
            @media all and (min-width: 768px) {
                margin: 0 5px;
            }
            @media all and (min-width: 1024px) {
                margin: 0 10px;
            }
        }
        .nav-link {
            color: $green-light;
            &--bordered {
                position: relative;
                border: 3px solid $green-light;
                padding: 12px 25px;
                white-space: nowrap;
                @media all and (max-width: 768px - 1px) {
                    display: inline-block;
                    min-width: 160px;
                    text-align: center;
                }
                &:before {
                    content: "";
                    position: absolute;
                    top: 7px;
                    right: 7px;
                    bottom: 7px;
                    left: 7px;
                    border: 3px solid $green-light;
                }
                &.active {
                    color: $green-light;
                }
            }
            &--bordered-yellow {
                border-color: #DABD76;
                &:before {
                    border-color: #DABD76;
                }
            }
        }
    }
}

.account,
.verify-email {
    &--offset {
        padding: 80px 24px;
    }
    .card {
        border-radius: 10px;
        border: unset;
        overflow: hidden;
        &-header {
            border-bottom: unset;
            border-radius: unset;
            font-family: $lora;
            font-size: 21px;
            line-height: 1.2;
            text-transform: uppercase;
            background-color: $white;
            @media all and (min-width: 768px) {
                padding: 40px 30px;
            }
        }
        &-body {
            position: relative;
            font-family: $avenir-next;
            font-size: 18px;
            @media all and (min-width: 768px) {
                padding: 0 30px 50px;
            }
        }
    }
}

.account {
    .card {
        &-body {
            .col-form-label {
                line-height: 1.2;
                @media all and (min-width: 768px) {
                    padding: 5px 0;
                }
            }
        }
    }
}
.verify-email {
    .card {
        &-body {
            button.btn {
                font-size: 18px;
            }
        }
    }
}

.user-controls {
    position: relative;
    border-radius: 15px;
    padding: 7px 10px;
    margin: 0 -24px;
    @media all and (min-width: 768px) {
        border-radius: 40px;
        padding: 10px 20px;
    }
    &:before {
        content: "";
        position: absolute;
        top: -1px;
        left: calc(50% - 75px);
        width: 150px;
        height: 2px;
        background-color: $black;
        @media all and (min-width: 768px) {
            left: calc(50% - 163px);
            width: 326px;
        }
    }
    &__search {
        position: relative;
        z-index: 2;
        max-width: 500px;
        max-height: 0;
        margin: 0 auto;
        padding-bottom: 10px;
        opacity: 0;
        transition: 0.5s max-height ease-in-out, 0.25s opacity ease-in-out;
        &--open {
            max-height: unset;
            opacity: 1;
        }
    }
    &__inner {
        position: relative;
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
        background-color: $main-light-gray;
        @media all and (min-width: 768px) {
            gap: 20px;
        }
    }
    &__item {
        display: inline-block;
        padding: 0 5px 0;
        transition: 0.25s background-color ease-in-out;
        cursor: pointer;
        @media all and (min-width: 768px) {
            padding: 5px 10px 0;
            border-radius: 10px;
        }
        &:hover {
            background-color: $main-light-gray-2;
        }
        &-icon {
            display: block;
            &-wrap {
                display: block;
                width: 30px;
                height: 30px;
                margin: 0 auto;
                @media all and (min-width: 768px) {
                    width: 65px;
                    height: 65px;
                }
            }
            &-svg {
                max-width: 100%;
                max-height: 100%;
            }
        }
        &-title {
            display: block;
            font-size: 6px;
            text-transform: uppercase;
            color: $black;
            @media all and (min-width: 768px) {
                font-size: 12px;
            }
            &-wrap {
                display: block;
                padding-top: 5px;
                text-align: center;
            }
        }
    }
}

.articles {
    background-color: $main-gray;
    &__category-block {
        margin-bottom: 20px;
    }
    &__title {
        margin: 0 0 35px 0;
        &-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            .articles__title-icon {
                margin: 0;
            }
        }
        &-icon {
            width: 45px;
            height: 45px;
            margin: 0 auto 10px;
            @media all and (min-width: 768px) {
                width: 85px;
                height: 85px;
            }
            &-svg {
                display: block;
                max-width: 100%;
                max-height: 100%;
            }
        }
        &-sub-icon {
            position: relative;
            width: 24px;
            height: 14px;
            margin: 0 auto;
            &:before,
            &:after {
                content: "";
                position: absolute;
                top: 6px;
                width: 15px;
                height: 3px;
                background-color: $black;
                transition: 0.35s transform ease-in-out;
            }
            &:before {
                left: 0;
                transform: rotate(45deg);
            }
            &:after {
                right: 0;
                transform: rotate(-45deg);
            }
            &--back-btn {
                width: 36px;
                height: 36px;
                margin: 0 0 0 -36px;
                cursor: pointer;
                &:before,
                &:after {
                    left: 10px;
                    transition: 0.25s background-color ease-in-out;
                }
                &:before {
                    top: 13px;
                    transform: rotate(135deg);
                }
                &:after {
                    top: 22px;
                    transform: rotate(-135deg);
                }
                &:hover {
                    &:before,
                    &:after {
                        background-color: lighten($main-dark-gray, 10%);
                    }
                }
            }
        }
    }
    &__content {
        margin-bottom: 30px;
    }
    &__user-controls {
        position: sticky;
        z-index: 20;
        bottom: 0;
        padding-top: 30px;
        &:after {
            content: "";
            position: absolute;
            z-index: -1;
            right: -24px;
            bottom: 0;
            left: -24px;
            height: 40px;
            background-color: $white;
        }
    }
}

.categories {
    &__content {
        margin: 0 -24px;
        @media all and (max-width: 1023px) {
            padding: 30px 24px;
        }
        @media all and (min-width: 1024px) {
            padding: 30px 24px 60px;
        }
        &-search {
            @media all and (max-width: 1023px) {
                margin-bottom: 30px;
            }
            @media all and (min-width: 1024px) {
                margin-bottom: 70px;
            }
        }
    }
    &__tiles {
        &-top {
            @media all and (max-width: 1023px) {
                padding-bottom: 25px;
            }
            @media all and (min-width: 1024px) {
                padding-bottom: 35px;
            }
        }
        &-bottom {
            @media all and (max-width: 1023px) {
                padding-top: 25px;
            }
            @media all and (min-width: 1024px) {
                padding-top: 35px;
            }
        }
    }
}
.podcasts {
    background-color: $main-light-green;
}

.videos {
    background-color: $main-dark-gray;
}

.articles,
.podcasts,
.videos {
    border-radius: 15px;
    padding: 0 24px;
    @media all and (min-width: 768px) {
        border-radius: 40px;
    }
    &--offset-top {
        padding-top: 40px;
    }
}
