#header {
    .header {
        $block-name: &;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 32px;
        @media all and (max-width: 767px) {
            padding: 15px 24px !important;
        }

        &__translate {
            &-item {
                display: inline-block;
                text-align: center;
                &:not(:last-child) {
                    margin-bottom: 5px;
                    @media all and (min-width: 768px) {
                        margin-bottom: 15px;
                    }

                }
                &-link {
                    &:hover {
                        svg path {
                            fill: $main-dark-gray;
                        }
                    }
                }
                &-button {
                    border-radius: 3px;
                    border-width: 2px;
                    font-weight: 600;
                    @media all and (max-width: 767px) {
                        margin: 0 auto;
                        padding: 3px 6px;
                        font-size: 9px;
                    }
                    @media all and (min-width: 768px) {
                        min-width: 50px;
                        padding: 7px 13px;
                    }
                }
                &-icon {
                    width: 25px;
                    height: 25px;
                    margin: 0 auto;
                    @media all and (min-width: 768px) {
                        width: 50px;
                        height: 50px;
                    }
                    &-i {
                        display: block;
                        max-width: 100%;
                        max-height: 100%;
                        path {
                            transition: 0.25s fill ease-in-out;
                        }
                    }
                }
                &-text {
                    display: block;
                    padding: 3px 0 0;
                    font-family: $avenir-next;
                    font-size: 5px;
                    line-height: 1.25;
                    letter-spacing: 0.5px;
                    font-weight: 600;
                    color: $main-dark-gray;
                    @media all and (min-width: 768px) {
                        padding: 5px 0 0;
                        font-size: 8px;
                        letter-spacing: 1px;
                    }
                    &--uppercase {
                        text-transform: uppercase;
                    }
                }
            }
        }

        &__socials,
        &__translate {
            @extend %listreset;
        }

        &__socials {
            min-width: 70px;
            @media all and (min-width: 768px) {
                display: flex;
                align-items: center;
                gap: 16px;
                width: 25%;
            }
            @media all and (min-width: 1024px) {
                min-width: 74px;
            }
            &-item {
                .button {
                    position: relative;
                    z-index: 2;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 70px;
                    height: 36px;
                    font-size: 16px;
                    padding: 3px 8px;
                    background-color: transparent;
                    @media all and (min-width: 1024px) {
                        width: 74px;
                    }
                    &:before,
                    &:after {
                        content: "";
                        position: absolute;
                    }
                    &:before {
                        z-index: 2;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        border: 3px solid $white;
                    }
                    &:after {
                        z-index: -1;
                        top: 8px;
                        right: 8px;
                        bottom: 8px;
                        left: 8px;
                        background-color: $white;
                    }
                    &:hover {
                        color: $yellow;
                        background-color: unset;
                    }
                    &:focus {
                        outline: 2px auto $white;
                    }
                }
                &:not(:last-child) {
                    @media all and (max-width: 767px) {
                        margin-bottom: 24px;
                    }
                }
                &--mob-order {
                    &-first {
                        @media all and (max-width: 767px) {
                            order: 1;
                            margin-bottom: 24px;
                        }
                    }
                    &-second {
                        @media all and (max-width: 767px) {
                            order: 2;
                            margin-bottom: 0 !important;
                        }
                    }
                }
            }
            &--mob-order {
                @media all and (max-width: 767px) {
                    display: inline-flex;
                    flex-direction: column;
                    #{$block-name} {
                        &__socials {
                            &-item {
                                .button {
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        &__logo {
            max-width: 110px;
            @media all and (min-width: 768px) {
                max-width: 235px;
            }
            &-link {
                display: block;
                width: 110px;
                @media all and (min-width: 768px) {
                    width: 250px;
                }
            }
            &-image {
                max-width: 100%;
                max-height: 100%;
            }
        }

        &__controls {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
        }
        &__translate,
        &__controls {
            width: 30px;
            margin-top: auto;
            @media all and (min-width: 768px) {
                width: 75px;
            }
        }
    }
}
