@import '_variables';

// list reset
%listreset {
	margin: 0;
	padding: 0;
	list-style: none;
}

// clearfix
%clearfix {
	&:after {
		content: '';
		display: block;
		clear: both;
	}
}

.clearfix {
	@extend %clearfix;
}

// transition
%transition {
	transition: all 0.3s ease-in-out;
}

/// * Helper class to truncate and add ellipsis to a string too long for it to fit
/// * on a single line.
/// * 1. Prevent content from wrapping, forcing it on a single line.
/// * 2. Add ellipsis at the end of the line.
.ellipsis {
	white-space: nowrap; /* 1 */
	text-overflow: ellipsis; /* 2 */
	overflow: hidden;
}

@mixin placeholder {
	&::-webkit-input-placeholder {@content}
	&:-moz-placeholder           {@content}
	&::-moz-placeholder          {@content}
	&:-ms-input-placeholder      {@content}
}

.default-text {
    font-family: $avenir-next;
    font-size: 14px;
}

.bg-light-gray {
    background-color: $main-light-gray;
}

.bg-main-gray {
    background-color: $main-gray;
}

.bg-main-light-green {
    background-color: $main-light-green;
}

.bg-main-dark-gray {
    background-color: $main-dark-gray;
}

.border-radius {
    border-radius: 40px;
}

.border-radius-top {
    border-radius: 40px 40px 0 0;
}

.section {
    &__title {
        position: relative;
        margin-bottom: 20px;
        border: none;
        padding: 0 20px 15px;
        font-family: $lora;
        font-size: 30px;
        font-weight: normal;
        line-height: 1.2;
        text-align: center;
        text-transform: uppercase;
        color: $main-dark-gray;
        background-color: transparent;

        &:after {
            content: '';
            position: absolute;
            top: 100%;
            left: calc(50% - 118px);
            width: 236px;
            height: 1px;
            background-color: $black;
        }
    }
    &-fixed {
        position: fixed;
        padding: 20px 20px 30px;
        background-color: $white;
        box-shadow: 0 0 2px rgba(0,0,0,.25);
        &--bottom {
            z-index: 10;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
}
