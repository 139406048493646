.footer {
    @media all and (min-width: 768px) {
        padding: 50px 24px;
    }
    &__logo {
        margin: 0 auto 30px;
        max-width: 110px;
        @media all and (min-width: 768px) {
            max-width: 235px;
        }
        &-link {
            display: inline-block;
            width: 110px;
            height: auto;
            @media all and (min-width: 768px) {
                width: 235px;
            }
        }
        &-image {
            max-width: 100%;
            max-height: 100%;
        }
    }
    &__copy {
        display: block;
        margin-bottom: 15px;
        font-family: $avenir-next;
        font-size: 16px;
        line-height: 1.25;
        text-align: center;
    }
    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &-list {
            display: flex;
            align-items: center;
            width: 100%;
            margin: 0;
            padding: 0;
            list-style: none;
            @media all and (max-width: 767px) {
                flex-direction: column;
            }
            @media all and (min-width: 1024px) {
                max-width: 900px;
            }
            &-item {
                padding: 0 10px;
                text-align: center;
                @media all and (max-width: 767px) {
                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }
                }
                @media all and (min-width: 768px) {
                    //width: 33.3333%;
                    width: 50%;
                }
            }
        }
        &-link {
            font-family: $avenir-next;
            font-size: 16px;
            line-height: 1.25;
            color: $black;
            transition: 0.25s color ease-in-out;
            &:hover,
            &:active,
            &:focus {
                color: $main-dark-gray;
            }
        }
    }
    &--offset-bottom {
        padding-bottom: 100px;
    }
    &--bg-light-gray {
        background-color: $main-light-gray;
    }
    &--login-page-mobile-tablet {
        @media all and (max-width: 1023px) {
            position: relative;
            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                right: 0;
                bottom: 100%;
                left: 0;
                min-height: 40vh;
                background-color: $main-light-gray;
            }
        }

    }
}
