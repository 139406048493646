@import "variables";

// Avenir Next
@font-face {
    font-family: $avenir-next-font-family;
    src: local('Avenir Next Regular'), local('Avenir-Next-Regular'),
    url('#{$avenir-next-font-path}/#{$avenir-next-font-family}-Regular.woff2') format('woff2'),
    url('#{$avenir-next-font-path}/#{$avenir-next-font-family}-Regular.woff') format('woff'),
    url('#{$avenir-next-font-path}/#{$avenir-next-font-family}-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $avenir-next-font-family;
    src: local('Avenir Next Medium'), local('Avenir-Next-Medium'),
    url('#{$avenir-next-font-path}/#{$avenir-next-font-family}-Medium.woff2') format('woff2'),
    url('#{$avenir-next-font-path}/#{$avenir-next-font-family}-Medium.woff') format('woff'),
    url('#{$avenir-next-font-path}/#{$avenir-next-font-family}-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $avenir-next-font-family;
    src: local('Avenir Next Demi'), local('Avenir-Next-Demi'),
    url('#{$avenir-next-font-path}/#{$avenir-next-font-family}-Demi.woff2') format('woff2'),
    url('#{$avenir-next-font-path}/#{$avenir-next-font-family}-Demi.woff') format('woff'),
    url('#{$avenir-next-font-path}/#{$avenir-next-font-family}-Demi.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $avenir-next-font-family;
    src: local('Avenir Next Bold'), local('Avenir-Next-Bold'),
    url('#{$avenir-next-font-path}/#{$avenir-next-font-family}-Bold.woff2') format('woff2'),
    url('#{$avenir-next-font-path}/#{$avenir-next-font-family}-Bold.woff') format('woff'),
    url('#{$avenir-next-font-path}/#{$avenir-next-font-family}-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

// Lora
@font-face {
    font-family: $lora-font-family;
    src: url('#{$lora-font-path}/#{$lora-font-family}-Regular.eot');
    src: local('Lora Regular'), local('Lora-Regular'),
    url('#{$lora-font-path}/#{$lora-font-family}-Regular.eot?#iefix') format('embedded-opentype'),
    url('#{$lora-font-path}/#{$lora-font-family}-Regular.woff2') format('woff2'),
    url('#{$lora-font-path}/#{$lora-font-family}-Regular.woff') format('woff'),
    url('#{$lora-font-path}/#{$lora-font-family}-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
