html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  max-height: 1000000px;
}

body {
  color: $base-text-color;
  background: $white;
  font: #{$font-size-base}/#{$line-height-base} $base-font-family;
  min-width: $base-min-width;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// adaptive images
img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

// google map fix
.gm-style img {
  max-width: none
}

// Typography
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h {
  font-family: $headings-font-family;
  font-weight: bold;
  margin: 0 0 0.5em;
  color: $headings-color;
}

h1,
.h1 {
  font-size: $h1-font-size;
}

h2,
.h2 {
  font-size: $h2-font-size;
}

h3,
.h3 {
  font-size: $h3-font-size;
}

h4,
.h4 {
  font-size: $h4-font-size;
}

h5,
.h5 {
  font-size: $h5-font-size;
}

h6,
.h6 {
  font-size: $h6-font-size;
}

p {
  margin: 0 0 1em;
}

a {
  color: $base-link-color;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}
